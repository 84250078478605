/**
 * @notice 车辆保险
 * @author hhq
 */
import http from "../http"
//新增保险
export const addOne =(params)=>{
    return http.postRequest('/tinsurance/add',params)
}

//车牌搜索
export const selectCarList =(params)=>{
    return http.getRequest('/car/selectCarList',params)
}

//保险提醒
export const remindList =(params)=>{
    return http.getRequest('/tinsurance/remindList',params)
}


