<template>
  <div class="index-container">
    <div class="bread">
      <span @click="home()" class="gray">操作台 / </span>
      <span>保险提醒</span>
    </div>
    <el-row class="table-body">
      <div class="line clearfix">
        <h5 class="fl title">保险提醒</h5>
        <div class="fr search">
          <el-input size="small" type="text" placeholder="请输入车牌号" v-model="carNum">
            <template slot="prefix">
              <el-button icon="el-icon-search" type="text" disabled></el-button>
            </template>
          </el-input>
          <el-button type="primary" size="small" @click="getInsuranceList">查询</el-button>
          <el-button type="success" size="small" @click="createOrEditNew">新增</el-button>
        </div>
      </div>
      <div class="list">
        <el-table :data="tableData" style="width: 100%">
          <el-table-column fixed type="index" label="序号"></el-table-column>
          <el-table-column fixed prop="carNum" label="车牌"></el-table-column>
          <el-table-column fixed prop="businessName" label="商业保险公司"></el-table-column>
          <el-table-column fixed prop="" label="详情">
            <template slot-scope="scope">
              <div>
                <span>结束时间：</span>
                <span>{{scope.row.compulsoryEndTime}}</span>
              </div>
              <div>
                <span v-if="scope.row.businessOverDue > 0" style="color: blue">
                  {{scope.row.businessOverDue}}天后逾期</span>
                <span v-if="scope.row.businessOverDue === 0" style="color: green">今日后逾期</span>
                <span v-if="scope.row.businessOverDue < 0" style="color: red">
                  逾期{{String(scope.row.compulsoryOverDue).replace('-', '')}}天</span>
              </div>
            </template>
          </el-table-column>
          <el-table-column fixed prop="compulsoryName" label="交强险保险公司">

          </el-table-column>
          <el-table-column fixed prop="" label="详情">
            <template slot-scope="scope">
              <div>
                <span>结束时间：</span>
                <span>{{scope.row.compulsoryEndTime}}</span>
              </div>
              <div>
                <span v-if="scope.row.compulsoryOverDue > 0" style="color: blue">
                  {{scope.row.compulsoryOverDue}}天后逾期</span>
                <span v-if="scope.row.compulsoryOverDue === 0" style="color: green">今日后逾期</span>
                <span v-if="scope.row.compulsoryOverDue < 0" style="color: red">
                  逾期{{String(scope.row.businessOverDue).replace('-', '')}}天
                </span>
              </div>
            </template>
          </el-table-column>
          <el-table-column fixed="right" label="操作">
            <template slot-scope="scope">
              <el-button type="text" size="small" @click="catOrEditNew(scope.row)">查看</el-button>
              <el-button type="text" size="small">删除</el-button>
            </template>
          </el-table-column>
        </el-table>
      </div>
      <div class="pages">
        <el-pagination
          background
          layout="prev, pager, next"
          @current-change="handleCurrentChange"
          :hide-on-single-page="showPage"
          :page-size="pageSize"
          :total="total"
        ></el-pagination>
      </div>
    </el-row>
    <!-- <edit-shelves ref="shelves" v-on:getList="getList" ></edit-shelves>
    <car-house ref="catCar"></car-house>-->
    <addInsurance ref="add" @getInsuranceList="getInsuranceList"></addInsurance>
  </div>
</template>



<script>
import addInsurance from "./components/addInsurance";
import {getStore} from "@/utils/storage";
import {remindList} from '@/utils/api/inssurance'

export default {
    name: 'bxtx',
  components: {
    addInsurance
  },
  data() {
    return {
      total: 0, //总条数
      pageNo: 1,
      pageSize: 10,
      showPage: true,
      tableData: [

      ], //表格数据
      keywords: "", //搜索关键字
      editOne: "" ,//编辑数据
      carNum: '',
    };
  },
  methods: {
    home(){
      this.goHome()
    },
      handleCurrentChange(val){
        this.pageNo = val;
        this.getInsuranceList()
      },
      createOrEditNew(row){
          this.$refs['add'].createOrEdit(row)
      },
      catOrEditNew(row){
        this.$refs['add'].show(row)
      },
      getInsuranceList(){
        let data = {
          companyId: getStore('companyId'),
          size: this.pageSize,
          pages: this.pageNo,
        }
        if(this.carNum !== ''){
          this.data.carNum = this.carNum
        }
        remindList(data).then(res => {
          if(res.code === 0){
            this.tableData = res.data.records
            this.total = res.data.total
          }else{
            this.$message.error(res.msg)
          }
        })
      }
  },
  created() {
      this.getInsuranceList()
  }
};
</script>

<style  lang="less">
.table-body {
  background-color: #ffffff;
  padding: 30px;
  padding-top: 0;
  .line {
    height: 90px;
    line-height: 90px;
    .title {
      font-size: 16px;
      font-weight: 800;
      color: #333333;
    }
    .search {
      display: flex;
      align-items: center;
      align-content: center;
      justify-items: flex-end;
      .el-input {
        font-size: 12px;
        margin-right: 12px;

        .el-input__inner {
          border: none;
          background-color: #fafafa;
          padding-left: 50px;
        }
        .el-input__prefix {
          width: 40px;
          &:after {
            content: "";
            width: 1px;
            height: 15px;
            background-color: #d8d7d7;
            position: absolute;
            right: 0;
            top: 50%;
            transform: translateY(-50%);
          }
        }
      }
    }
  }
}
</style>
