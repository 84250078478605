<template>
  <el-dialog :title="title" :visible.sync="dialogVisible" width="60%">
    <div>
      <el-form :model="form" class="demo-form-inline">
        <div style="display: flex;">
          <div >
            <el-form-item label="交强险保单" prop="carNum">
              <el-upload
                  :disabled="disabled"
                  class="avatar-uploader"
                  :action="upLoadUrl"
                  :before-upload="beforeAvatarUpload"
                  :headers="headers"
                  :on-success="handleCompulsoryUrlSuccess"
                  :show-file-list="false">
                <img v-if="form.compulsoryUrl" :src="form.compulsoryUrl" class="avatar">
                <div class="explain" v-else>
                  <i class="el-icon-plus avatar-uploader-icon"></i>
                  <p class="tips">只支持.jpg/.png格式</p>
                </div>
              </el-upload>
            </el-form-item>
          </div>
          <div style="margin-left: 5vw">
            <el-form-item label="商业险保单" prop="carNum">
              <el-upload
                  :disabled="disabled"
                  class="avatar-uploader"
                  :action="upLoadUrl"
                  :before-upload="beforeAvatarUpload"
                  :headers="headers"
                  :on-success="handleBusinessUrlSuccess"
                  :show-file-list="false">
                <img v-if="form.businessUrl" :src="form.businessUrl" class="avatar">
                <div class="explain" v-else>
                  <i class="el-icon-plus avatar-uploader-icon"></i>
                  <p class="tips">只支持.jpg/.png格式</p>
                </div>
              </el-upload>
            </el-form-item>
          </div>
        </div>
        <div>
          <el-form-item label="车牌">
<!--            <el-input v-model="form.carNum" placeholder="请输入车牌"></el-input>-->
            <el-select
                v-model="form.carNum"
                :disabled="disabled"
                filterable
                remote
                reserve-keyword
                @change="handleCarNumChange"
                placeholder="请输入关键词"
                :remote-method="selectCarList"
                :loading="loading">
              <el-option
                  v-for="item in options"
                  :key="item.value"
                  :label="item.carNum"
                  :value="item.carNum">
              </el-option>
            </el-select>
          </el-form-item>

        </div>
        <div class="info">
          <h4>交强险信息</h4>
        </div>
        <div>
          <el-row>
            <el-col :span="12">
              <el-form-item label="开始时间">
                <el-date-picker
                    :disabled="disabled"
                    v-model="form.compulsoryBeginTime"
                    type="date"
                    value-format="yyyy-MM-dd"
                    placeholder="选择保险开始时间">
                </el-date-picker>
              </el-form-item>
            </el-col>
            <el-col :span="12">
              <el-form-item label="结束时间">
                <el-date-picker
                    :disabled="disabled"
                    v-model="form.compulsoryEndTime"
                    type="date"
                    value-format="yyyy-MM-dd"
                    placeholder="选择保险结束时间">
                </el-date-picker>
              </el-form-item>
            </el-col>
          </el-row>
          <el-row>
            <el-form-item label="保险公司">
              <el-input v-model="form.compulsoryName" placeholder="保险公司全称"
                        :disabled="disabled"
              ></el-input>
            </el-form-item>
          </el-row>
        </div>
        <div class="info">
          <h4>商业险信息</h4>
        </div>
        <div>
          <el-row>
            <el-col :span="12">
              <el-form-item label="开始时间">
                <el-date-picker
                    :disabled="disabled"
                    v-model="form.businessBeginTime"
                    type="date"
            value-format="yyyy-MM-dd"
                    placeholder="选择保险开始时间">
                </el-date-picker>
              </el-form-item>
            </el-col>
            <el-col :span="12">
              <el-form-item label="结束时间">
                <el-date-picker
                    :disabled="disabled"
                    v-model="form.businessEndTime"
                    type="date"
            value-format="yyyy-MM-dd"
                    placeholder="选择保险结束时间">
                </el-date-picker>
              </el-form-item>
            </el-col>
          </el-row>
          <el-row>
            <el-form-item label="保险公司">
              <el-input v-model="form.businessName" placeholder="保险公司全称" :disabled="disabled"></el-input>
            </el-form-item>
          </el-row>
        </div>
        <el-row>
          <el-form-item label="预期提醒">
            <el-radio-group v-model="form.remindDay" size="small" :disabled="disabled">
              <el-radio label="15" border>提前15天</el-radio>
              <el-radio label="30" border>提前30天</el-radio>
            </el-radio-group>
          </el-form-item>
        </el-row>
      </el-form>
    </div>
    <span slot="footer" class="dialog-footer" v-if="!disabled">
      <el-button @click="close">取 消</el-button>
      <el-button type="primary" @click="handleSave" >确 定</el-button>
    </span>
  </el-dialog>
</template>


<script>
import {getStore} from "@/utils/storage";
import config from "@/utils/config";
import {addOne, selectCarList} from "@/utils/api/inssurance";

export default {
  name: "addInsurance",
  props: {},
  data() {
    return {
      dialogVisible: false,
      title: '',
      form: {
        "businessBeginTime": "", //商业险开始时间
        "businessEndTime": "",
        "businessName": "",
        "businessUrl": "",  //保单地址
        "carNum": "",
        "companyId":  getStore('companyId') ,
        "compulsoryBeginTime": "", //交强险
        "compulsoryEndTime": "",
        "compulsoryName": "",
        "compulsoryUrl": "",
        "createBy": "",
        remindDay: ''
      },
      headers: {
        // :
        authorization: 'Bearer ' + getStore('access_token')
      },
      upLoadUrl: config.baseUrl + '/app/tool/uplodFile',
      options: [],  //车辆列表
      value: [],
      list: [],
      loading: false,
      disabled: false
    }
  },
  methods: {
    close(){

      this.dialogVisible = false
    },
    createOrEdit(row) {
      this.dialogVisible = true
      if (row.id) {
        this.title = "编辑保险"
      } else {
        this.form = {}
        this.title = "新增保险"
      }
    },
    show(row) {
      this.dialogVisible = true
      if (row) {
        this.title = "查看保险"
        this.form = Object.assign(this.form, row, {})
        this.form.businessUrl = getStore('imgBase') + this.form.businessUrl
        this.form.compulsoryUrl = getStore('imgBase') + this.form.compulsoryUrl
        this.form.remindDay = String(this.form.remindDay)
        this.disabled = true
      }
    },
    handleCarNumChange(value){
      this.options.map(item => {
        if(item.carNum === value){
          this.form.carId = item.id
        }
      })
    },
    beforeAvatarUpload(file){
      const isJPG = file.type === 'image/jpg';
      const isPng = file.type === 'image/png'
      const isJPEG = file.type === 'image/jpeg'
      const isLt2M = file.size / 1024 / 1024 < 5;
      if((isJPG && isLt2M) || (isPng && isLt2M) || (isJPEG && isLt2M) ){
        return isLt2M;
      }else{
        this.$message.error('上传图片只能是 JPG或PNG 格式并小于5M');
        return  false
      }
    },
    handleBusinessUrlSuccess(res, file) {
      this.form.businessUrl = getStore('imgBase') + res.data.url
    },
    handleCompulsoryUrlSuccess(res, file) {
      this.form.compulsoryUrl = getStore('imgBase') + res.data.url
    },
    //搜索车牌远程
    selectCarList(value){
      if (value !== '') {
        this.loading = true;
        // setTimeout(() => {
        //   this.loading = false;
        //   this.options = this.list.filter(item => {
        //     return item.label.toLowerCase()
        //         .indexOf(query.toLowerCase()) > -1;
        //   });
        // }, 200);
        selectCarList({carNum: value}).then(res=>{
          this.loading = false;
            if(res.code ===0){
              this.options = res.data
            }
        })
      } else {
        this.options = [];
      }
  },
    handleSave(){
      let data = JSON.parse(JSON.stringify(this.form))
      data.businessUrl = data.businessUrl.replace(getStore('imgBase'), '')
      data.compulsoryUrl = data.compulsoryUrl.replace(getStore('imgBase'), '')
      addOne(data).then(res => {
        if(res.code === 0){
          this.$message.success("添加成功")
          this.form = {}
          this.dialogVisible = false
          this.$emit('getInsuranceList')
        }else{
          this.$message.error(res.msg)
        }
      })
    }
  }
};
</script>

<style scoped>
</style>
